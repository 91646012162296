<template>
	<page-index page="company"></page-index>
</template>

<script>
	import pageIndex from "@/components/PageIndex.vue";
	export default {
		name: "CompanyIndex",
		components: { pageIndex }
	}
</script>