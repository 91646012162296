<template>
	<nav class="view-navigation" :style="{ top: navTop + 'px' }" :class="$responsive">
		<ul class="view-navigation__items">
			<li class="view-navigation__item" v-for="item in items" :key="item.routeName">
				<router-link :to="{ name: item.routeName }">
					{{ item.text }}
				</router-link>
			</li>	
		</ul>
	</nav>
</template>

<script>
	export default {
		props: {
			items: Array,
			default: () => []
		},
		data() {
			return {
				navTop: 0 
			}
		},
		mounted() {
			const menu = document.querySelector('.site-header');
			if (menu) {
				this.navTop = menu.offsetHeight;
			}
		}
	}
</script>

<style scoped>
.view-navigation {
	position: fixed;
	width: 100vw;
	left: 0;
	right: 0;
	z-index: 100;
	background-color: rgba(255, 255, 255, .33);
	text-align: left;
	overflow-x: auto;
}

.view-navigation.mobile {
	background-color: rgba(255,255,255,0.97);
}

.view-navigation__items {
	display: inline-flex;
	justify-content: flex-start;
	padding-left: 1rem;
}

.view-navigation__items:hover {
	background-color: rgba(255, 255, 255, .99);
}

.mobile .view-navigation__items {
	padding: 0 1rem 0 .3rem;
}

.view-navigation__item {
	font-size: .9375rem;
	display: inline-block;
	padding: .5em 1rem;
	white-space: nowrap;
}

.tablet .view-navigation__item {
	font-size: 1.0625rem;
}

.view-navigation__item a {
	color: var(--secondary-color);
	text-decoration: none;
	padding-bottom: .25em;
}

.view-navigation__item a:hover {
	color: var(--primary-color)
}

a.router-link-exact-active {
	border-bottom: 2px solid var(--primary-color);
}
</style>